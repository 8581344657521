// ----------------
// FILE UPLOADER
//
// Component File
// ----------------

@import '~styles/vars';


:local(.wrapper) {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}


:local(.input) {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
