@import (css) url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

@import 'vars';


html, body {
  height: 100%;
  width: 100%;
  font-family: @base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  overflow: hidden;
}


#canvas {
  overflow: hidden;
}
