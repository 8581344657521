// ----------------
// SIDEBAR
//
// Component File
// ----------------

@import '~styles/vars';

@separator-height: 10px;


:local(.sidebar) {
  position: absolute;
  top: 100%;
  right: 0;
  background: @secondary-blue;
  height: ~'calc(100vh - 100%)';
  box-shadow: inset -2px -2px 3px rgba(0, 0, 0, 0.25), inset 2px -2px 3px rgba(0, 0, 0, 0.25);
  transform: translateX(100%);
  transition: all @base-transition-time ease-in-out;
}


:local(.visible) {
  composes: sidebar;
  transform: translateX(0);
}


:local(.separator) {
  position: relative;
  height: @separator-height;
  background: @tertiary-blue;
  width: 100%;
  margin-bottom: @base-padding-small;
}


:local(.content) {
  padding: @base-padding;
}


:local(.row) {
  margin: @base-margin * 2 0;
  color: @white;
  font-size: 0.9em;
  transition: all @base-transition-time ease-in-out;

  &:hover {
    color: @tertiary-blue;
    cursor: pointer;
  }
}


:local(.text) {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > i {
    margin-right: 10px;
  }
}
