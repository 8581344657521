// ----------------
// TOPBAR
//
// Component File
// ----------------

@import '~styles/vars';


:local(.topbar) {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  z-index: 9;
  background: @primary-blue;
  box-shadow: 0px 3px 12px rgba(0,0,0,0.15);
  transition: all @base-transition-time ease-in-out;
}


:local(.section) {
  margin-left: @base-margin * 2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:first-child {
    margin-left: 0;
  }
}


:local(.rightSection) {
  composes: section;
  margin-left: auto;
}


:local(.title) {
  color: @text-color-primary;
  padding: @base-padding;
  text-transform: uppercase;
  font-size: 1em;
}
