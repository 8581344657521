// ----------------
// BRICK PICKER
//
// Component File
// ----------------

@import '~styles/vars';


@brick-height: 60px;
@tip-size: 7px;


:local(.brickPicker) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


:local(.brick) {
  color: @white;
  height: @brick-height;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}


:local(.brickIcon) {
  height: 40px;
  // margin-right: @base-margin-small;
}


:local(.picker) {
  position: absolute;
  top: (@brick-height + @base-margin + 5px);
  left: -(@base-margin / 2);
  padding: @base-padding;
  background: @secondary-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid @primary-blue;

  &:after {
    content: ' ';
    position: absolute;
    top: -(@tip-size);
    left: @base-margin;
    border-left: @tip-size solid transparent !important;
    border-right: @tip-size solid transparent !important;
    border-bottom-width: @tip-size;
    border-bottom-style: solid;
    border-color: @secondary-blue;
  }
}


:local(.brickExample) {
  margin-right: @base-margin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-right: 0;
  }
}


:local(.brickThumb) {
  height: 50px;
  margin-bottom: @base-margin-small;
  padding: @base-padding-small;
  background-color: @white;
  border-radius: 10px;
  box-shadow: 0px 3px 7px rgba(0,0,0,0.6);
  transition: all @base-transition-time ease-in-out;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0px 5px 10px rgba(0,0,0,0.4);
  }
}


:local(.selected) {
  composes: brickThumb;
  background-color: @tertiary-blue;
}


:local(.label) {
  color: @white;
}
